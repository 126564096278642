import React from "react";
// import Seo from "../components/Seo";
import Seo from "react-seo-component";

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" description="404 page" />
    <h1>404: Not Found</h1>
    <p style={{ fontSize: "1rem", margin: "1rem" }}>Page not found.</p>
  </>
);

export default NotFoundPage;
